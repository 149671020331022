@font-face {
  font-family: 'LexendBlack';
  src: url('../theme/font/Lexend-Black.woff') format('woff');
  // url('../theme/font/') format('woff2');
}
@font-face {
  font-family: 'LexendBold';
  src: url('../theme/font/Lexend-Bold.woff') format('woff');
  // url('../theme/font/MailSansRoman-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'LexendLight';
  src: url('../theme/font/Lexend-Light.woff') format('woff');
  // url('../theme/font/MailSansRoman-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'LexendMedium';
  src: url('../theme/font/Lexend-Medium.woff') format('woff');
  // url('../theme/font/MailSansRoman-DemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'LexendRegular';
  src: url('../theme/font/Lexend-Regular.woff') format('woff');
  // url('../theme/font/MailSansRoman-DemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'AnekLight';
  src: url('../theme/font/AnekMalayalam-Light.woff') format('woff');
  // url('../theme/font/MailSansRoman-DemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'LexendExtraLight';
  src: url('../theme/font/Lexend-ExtraLight.woff') format('woff');
  // url('../theme/font/MailSansRoman-DemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'LexendSemiBold';
  src: url('../theme/font/Lexend-SemiBold.woff') format('woff');
  // url('../theme/font/MailSansRoman-DemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'CeraProLight';
  src: url('../theme/font/Cera\ Pro\ Light.woff') format('woff');
  // url('../theme/font/MailSansRoman-DemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'OxaniumLight';
  src: url('../theme/font/Cera\ Pro\ Light.woff') format('woff');
  // url('../theme/font/MailSansRoman-DemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'AnekSemiBold' !important;
  src: url('../theme/font/AnekMalayalam_Condensed-SemiBold.woff') format('woff');
  // url('../theme/font/MailSansRoman-DemiBold.woff2') format('woff2');
}
// * {
//   font-family: 'Lexend' !important;
// }

:root {
  // --primary-color: #ef4923;
  --primary-color: #34708c;
  --secondary-color: #65b492;
  --light-white-color: #edf8f4;
}

.css-9d287o-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #666;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}
.css-g021ew-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px !important;
}
// .select-form {
//   .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
//     border-color: #666 !important;
//   }
// }
.css-znbc8-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #666 !important;
  border: none !important;
}
.css-g0vbem.Mui-focused .MuiOutlinedInput-notchedOutline {
}
.css-1d6ffrd {
  // background-color: #212121;
  color: white;
  box-shadow: rgba(92, 87, 87, 0.16) 0px 1px 4px;
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  .css-ac28eu-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
    background-color: #212121;
    color: white;
    box-shadow: rgba(92, 87, 87, 0.16) 0px 1px 4px;
  }
}
.font-demi-bold {
  font-family: 'LexendBold';
}
.font-bold {
  font-family: 'LexendBold' !important;
}
.font-medium {
  font-family: 'LexendMedium';
}
.font-regular {
  font-family: 'LexendRegular' !important;
}
.font-normal-style {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px; /* 140% */
  display: flex;
}
.font-normal-common {
  font-family: 'Lexend';
  font-weight: 100;
  color: #999999;
}
.font-normal {
  font-family: 'Lexend';
  font-weight: 700;
  font-size: 48;
}
.font-color-home {
  background: linear-gradient(103deg, #d24a28 33.09%, #fea23d 94.28%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'MailSansRomanBold';
  // color: ;
  font-weight: 700;
}

.home-caption {
  display: flex !important;
  align-items: center;
  text-align: center;
}
.dm-san-serif {
  font-family: 'DM Sans', sans-serif;
}
.css-bl06gw {
  padding-top: 0px;
}
.css-518swa-MuiGrid-root {
  padding: 0px;
}
.home-page-mal-content-p1 {
  font-size: 15px;
  line-height: 22px;
  // font-weight: 500;
  font-family: 'AnekLight' !important;
}
.home-page-mal-content-p2 {
  font-size: 15px;
  // line-height: 22px;
  font-weight: 600;
  font-family: 'AnekSemiBold' !important;
}
.react-player {
  .react-player-video {
    z-index: 1;
    iframe {
      border-radius: 20.6498px !important;
    }
  }
}
.header-links {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.image-box {
  display: flex;
  justify-content: center;
  img {
    width: 80%;
    height: 100%;
    object-fit: contain;
    @media screen and (max-width: 460px) {
      width: 100%;
    }
  }
}
.collage-box-content-header {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 218.75% */
}
.collage-box-content-sub-header {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.collage-description {
  color: #949494;
  text-align: justify;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.mini-card-curriculum-collage {
  color: #f1f1f1;
  font-family: 'DM Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.stack-title {
  display: inline-block;
  overflow: hidden;
}

.stack-title span {
  display: inline-block;
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.nearBy-head {
  img {
    width: 80px;
    height: 70px;
    object-fit: contain;
    margin-bottom: 0px;
    @media screen and (max-width: 460px) {
      margin-bottom: 10px;
    }
  }
}

button {
  cursor: pointer;
}

.outer-container {
  max-width: 800px;
  margin: auto;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.outer-container > div {
  flex: 1;
  padding: 20px;
}

.outer-container .carousel-wrapper {
  background: var(--orange);
  display: flex;
  flex-direction: column;
}

.outer-container .content {
  background: var(--white);
}

.carousel-button {
  display: flex;
  align-self: center;
  padding: 10px;
  max-width: 50px;
  border-radius: 30px;
  background: var(--orange);
  border: 2px solid var(--white);
}

.carousel-button svg {
  height: 26px;
  width: 26px;
}

.carousel-button path {
  fill: var(--white);
}

.outer-container .carousel-wrapper p,
.carousel-item {
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
  line-height: 1.3;
}

.leading-text {
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: 6px;
}

.carousel {
  height: 100%;
  display: flex;
  flex: 5;
  align-items: center;
  color: var(--white);
}

.slides {
  align-self: flex-start;
  flex: 1;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  position: relative;
  max-height: 250px;
  height: 250px;
}

.carousel-inner::before,
.carousel-inner::after {
  content: '';
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 110px;
  pointer-events: none;
}

.carousel-inner::before {
  top: 0;
  background: linear-gradient(0deg, hsla(0, 0%, 100%, 0) 0%, hsla(27, 100%, 48%, 1) 100%);
}

.carousel-inner::after {
  bottom: 0;
  background: linear-gradient(0deg, hsla(27, 100%, 48%, 1) 0%, hsla(0, 0%, 100%, 0) 100%);
}

.carousel-item {
  position: absolute;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  top: 112px;
  transition: transform 0.4s ease, opacity 0.4s ease;
}

.carousel-item {
  opacity: 0;
}

.carousel-item.visible {
  opacity: 1;
}

// .react-player {
//   width: 100%;

//   @media screen and (max-width: 768px) {
//     margin-bottom: 3rem;
//     margin-top: 3rem;
//     width: 100%;
//     height: 100px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     .react-player-video {
//       width: 100%;
//       height: 100% !important;
//       div {
//         width: 100% !important;
//       }
//     }
//   }
//   .el {
//     position: absolute;
//     z-index: 0;
//     &.el1 {
//       @media screen and (max-width: 768px) {
//         width: 81.9px;
//         height: 81.9px;
//         right: -30px;
//         bottom: -30px;
//         display: none;
//       }
//     }
//     &.el2 {
//       left: -70px;
//       top: -40px;
//       z-index: 2;
//       @media screen and (max-width: 768px) {
//         width: 71.77px;
//         height: 71.77px;
//         left: -30px;
//         top: -20px;
//         display: none;
//       }
//     }
//     &.el3 {
//       right: -65px;
//       bottom: 90px;
//       @media screen and (max-width: 768px) {
//         width: 25.33px;
//         height: 25.33px;
//         right: -30px;
//         bottom: 55px;
//         display: none;
//       }
//     }
//     &.el4 {
//       left: -50px;
//       top: 110px;
//       @media screen and (max-width: 768px) {
//         width: 16.89px;
//         height: 16.89px;
//         left: -25px;
//         top: 55px;
//         display: none;
//       }
//     }
//   }
//   // .react-player-video {
//   //   z-index: 1;
//   //   iframe {
//   //     border-radius: 44.6498px !important;
//   //     height: 400px;
//   //     @media screen and (max-width: 768px) {
//   //       // border-radius: 21.5311px;
//   //       border-radius: 0;
//   //     }
//   //   }
//   //   .react-player__preview {
//   //     border-radius: 44.6498px;
//   //     @media screen and (max-width: 768px) {
//   //       // border-radius: 21.5311px;
//   //       border-radius: 0;
//   //     }
//   //   }
//   // }
//   .ytp-chrome-top {
//     display: none !important;
//   }
// }
.mini-card-home-page {
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  text-align: center;
}
.mini-description {
  font-size: 11px;
  color: #f49935;
  text-align: center;
  padding: 5px 5px 5px 5px;
  min-height: 25px;
  max-height: 35px;
}
.main-description {
  font-size: 13px !important;
  color: #949494;
  font-family: 'DM Sans';
  font-weight: 400;
  line-height: 22px;
  // padding: 2px 5px 0px 5px;
  min-height: 100px;
  max-height: 100px;
}
.mini-card-last-header {
  color: #fff;
  font-family: 'Lexend';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  min-height: 25px;
  min-height: 60px;
  padding-left: 44px;
  padding-right: 44px;
}
.mini-card-last-list {
  color: #949494;
  font-family: 'DM Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
  padding: 5px;
  li {
    text-align: start;
  }
}
.mini-flip-data-features-mobile {
  margin-bottom: 3px;
  font-size: 12px;
  padding: 0;
}
.mini-flip-data-features {
  margin-bottom: 3px;
  padding-top: 10px;
}
.flip-card-back-title-1 {
  text-align: start;
  font-size: 17.206px;
  font-weight: 700;
}
.flip-card-back-title-2 {
  text-align: start;
  font-size: 17.206px;
  font-weight: 700;
  padding: 0px;
}
.flip-card-back-title-1-mobile {
  font-size: 14px;
  padding-top: 8px;
  text-align: start;
}
.flip-card-back-title-2-mobile {
  font-size: 14px;
  padding-top: 8px;
  text-align: start;
}
.mini-flip-data-benefits-mobile {
  font-size: 12px;
}
.mini-flip-data-benefits {
  // font-size: 14px;
  padding-top: 10px;
}
.flip-card {
  // background-color: transparent;
  min-height: 380px;
  max-width: 330px;
  perspective: 1000px;
  background-color: #212121;
  padding: 8px;
  box-shadow: none;
  margin-left: 2px;
  margin-bottom: 3px;
  border-radius: 10px;
}
.mini-card-last-header-mobile {
  font-size: 15px;
  color: #fff;
  font-family: 'Lexend';
}
.flip-card-mobile {
  min-height: 360px;
  max-height: 450px;
  margin-top: 5px;
  perspective: 1000px;
  background-color: #212121;
  padding: 1px;
  box-shadow: none;
  margin-left: 2px;
  margin-bottom: 3px;
  border-radius: 10px;
}
.flip-card-inner-mobile {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.flip-card-back-mobile {
}
// .flip-card-mobile:hover .flip-card-inner-mobile {
//   transform: rotateY(180deg);
// }

.flip-card-front-mobile,
.flip-card-back-mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front-mobile {
  background-color: #bbb;
  color: black;
}

.flip-card-back-mobile {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

.flip-card-course {
  min-height: 420px;
  max-height: 450px;
  margin-top: 5px;
  perspective: 1000px;
  background-color: #212121;
  padding: 1px;
  box-shadow: none;
  margin-left: 2px;
  margin-bottom: 3px;
  border-radius: 10px;
}
.flip-card-inner-course {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.flip-card-back-course {
}
// .flip-card-mobile:hover .flip-card-inner-mobile {
//   transform: rotateY(180deg);
// }

.flip-card-front-course,
.flip-card-back-course {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front-course {
  background-color: #bbb;
  color: black;
}

.flip-card-back-course {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

.flip-card-inner-course {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-course:hover .flip-card-inner-course {
  transform: rotateY(180deg);
}

.flip-card-front-course,
.flip-card-back-course {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front-course {
  background-color: #bbb;
  color: black;
}

.flip-card-back-course {
  background-color: #2980b9;
  color: white;
  transform: rotateY(180deg);
}

.nearby-title {
  color: #949494;
  text-align: center;
  font-family: 'Lexend';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 490px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.swiper-headline {
  font-size: 15px;
  color: #34708c;
  font-family: 'LexendLight' !important;
}
.after-button-submit-headline {
  color: #fff !important;
  text-align: center;
  font-family: 'DM Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32.029px;
  text-transform: uppercase;
  padding-bottom: 8px;
}
.after-button-submit {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.enquiry-box-heading {
  font-size: 42px;
  color: #fff;
  font-family: ' Lexend';
  font-style: normal;
  font-weight: 600;
  line-height: 107.523%;
}
.enquiry-box-heading-mobile {
  font-size: 32px;
  color: #fff;
  font-family: ' Lexend';
  font-style: normal;
  font-weight: 600;
  line-height: 107.523%;
}
.enquiry-box-description {
  color: #949494;
  font-family: 'DM Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
.card-img-samesize {
  width: 250px;
}
.img-headline {
  .MuiStack-root css-1stb0in-MuiStack-root {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}
.react-player {
  .ytp-cued-thumbnail-overlay-image {
    position: none;
  }
}
.text-inputs-home {
  .css-qok1ue-MuiInputBase-root-MuiFilledInput-root {
    height: 500px;
  }
  .css-qok1ue-MuiInputBase-root-MuiFilledInput-root {
    border-bottom-color: none;
    &:before {
      border-bottom-color: none;
    }
  }
}
.footer-copyrights {
  color: '#767676';
  font-family: 'Dm Sans';
  font-size: 16px;
  font-weight: 400;
}

.details-action-2 .details-action-icon-2:hover > a {
  color: var(--primary-colors);
  fill: var(--primary-colors);
}
.cart-dropdown-wrap {
  visibility: hidden;
}
.field-custom::placeholder,
#outlined-multiline-static::placeholder {
  color: #65b492;
  font-family: 'LexendLight' !important;
}

.details-action-2 .details-action-icon-2:hover .cart-dropdown-wrap {
  opacity: 1;
  visibility: visible;
  top: calc(100% + 10px);
}
.header-links-main {
  .header-links {
    display: flex !important;
    flex-wrap: wrap !important;
    flex-direction: row !important;
    margin-left: 220px !important;
  }
  .css-1oqqzyl-MuiContainer-root {
    .css-r6ewbb-MuiToolbar-root {
      display: flex !important;
      justify-content: space-between !important;
    }
  }
}
.css-913t18-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0px !important;
  border: 1px solid var(--secondary-color) !important;
  // border-color: #014ce3 !important;
}
.css-913t18-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--secondary-color) !important;
}
.css-8n1zyz-MuiStack-root {
  background-color: #ffffff;
}
// courses

.course-subtitle {
  color: #949494;
  text-align: justify;
  font-family: 'DM Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 15px;
}
.course-subtitle-mobile {
  color: #949494;
  // text-align: justify;
  font-family: 'DM Sans';
  font-size: 14px !important;
  font-style: normal;
  font-weight: 000;
  // line-height: normal;
}
.lab-switch-button {
  border-radius: 25px !important;
  border: none;
  background: transparent;
  color: #737373;
  padding: 8px 20px 8px 20px;
  &.MuiToggleButton-root:hover {
    color: rgb(121, 121, 121);
    background-color: #eac59d;
  }

  &.active {
    background-color: #f49935;
    color: #ffffff;
  }
}
.benefits-title {
  color: #fff;
  font-family: ' Lexend';
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.benefits-title-mobile {
  color: #fff;
  font-family: ' Lexend';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.main-description-course {
  max-height: 200px;
  min-height: 150px;
  color: #949494;
  text-align: center;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bg-image-1 {
  background-image: url(../../public/assets/images/Home/grids.png) !important;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 100% !important;
  /* background-size: inherit; */
  background-position: right;
}
.bg-image-2 {
  background-image: url(../../public/assets/images/Home/bg-image-2.png) !important;
  background-repeat: no-repeat;
  background-size: 100% !important;
}
.bg-image-3 {
  background-image: url(../../public/assets/images/Home/min.png) !important;
  background-repeat: no-repeat;
  background-size: 30% !important;
  background-position: left;
}

.mini-card-course-page {
  color: #fff;
  text-align: center;
  font-family: 'Lexend';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding: 30px 0px 30px 0px;
  min-height: 25px;
  max-height: 30px;
}
.mini-card-course-page-head {
  color: #fff;
  text-align: center;
  font-family: 'Lexend';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.content-box {
  .lab-box-content-header {
    font-size: 30px !important;
    color: #e1e1e1;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
  }

  .lab-box-content-subtitle {
    color: #949494 !important;
    font-family: 'DM Sans';
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
.content-box {
  .lab-box-content-header-mobile {
    font-size: 20px !important;
    color: #e1e1e1;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 600;
    // line-height: 60px;
  }

  .lab-box-content-subtitle-mobile {
    color: #949494 !important;
    font-family: 'DM Sans';
    font-size: 11px !important;
    font-style: normal;
    font-weight: 400;
  }
}
.course-video-header {
  color: #fff;
  text-align: center;
  font-family: 'Lexend';
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 60.748px; /* 142.857% */
}
.curriculum-title {
  color: #fff;
  text-align: center;
  font-family: 'Lexend';
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.curriculum-image {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
}
.curriculum-image-mobile {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.LabEquipment {
  .css-mhc70k-MuiGrid-root {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}
.feature-title {
  color: #fff;
  font-family: 'Lexend';
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.feature {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.client-list-header {
  color: #fff;
  text-align: center;
  font-family: 'Lexend';
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.collage-subtitle-mobile {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px;
}
.lab-image-container-1 {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 53px; /* 220.833% */
}
.lab-image-container-title {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  text-transform: uppercase;
}
.collage-subtitle {
  color: #949494;
  text-align: justify;
  font-family: 'DM Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.collage-after-main-content {
  color: #e1e1e1;
  text-align: center;
  font-family: 'Lexend';
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 146.667% */
}
.collage-after-main-content-mobile {
  color: #e1e1e1;
  text-align: center;
  font-family: 'Lexend';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.collage-mini-box-content-header {
  color: #fff;
  font-family: 'DM Sans';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -2.08px;
}
.collage-mini-box-content-subtitle {
  color: #949494;
  text-align: justify;
  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 15px;
}
.collage-mini-box-content-header-mobile {
  color: #fff;
  font-family: 'DM Sans';
  font-size: 16;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
}
.collage-mini-box-content-subtitle-mobile {
  color: #949494;
  text-align: justify;
  font-family: 'DM Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.facilitator-subtitle {
  color: #949494;
  text-align: justify;
  font-family: 'DM Sans';
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  padding-top: 25px;
}
.facilitator-box-content-header {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 218.75% */
}
.facilitator-box-content-sub-header {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.facilitator-description {
  color: #949494;
  text-align: justify;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.mini-basic-card {
  width: 1000px !important;
  padding: 0px !important;
}
.mini-card-curriculum {
  color: #f1f1f1;
  font-family: 'DM Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5px;
}
.list-items {
  color: #949494;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 30px;
}
.content-box {
  .css-1sn1f0l {
    padding: 25px;
  }
}

@media (max-width: 1024px) {
  .css-12e57b0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.enquiry-form {
  padding: 10px;
}
.test-main-header {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px; /* 117.391% */
}
.test-sub-header {
  color: #949494;
  font-family: 'DM Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
}
.register-box-description {
  color: #e1e1e1;
  font-family: 'DM Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  text-transform: capitalize;
}
.register-box-heading {
  color: #e1e1e1;
  font-family: ' Lexend';
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 107.523%; /* 34.407px */

  .sub-heading {
    color: #e1e1e1;
    font-family: ' Lexend';
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 107.523%;
  }
}
.win-exiting-prize {
  color: #e1e1e1;
  text-align: center;
  font-family: 'Lexend';
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 54px; /* 120% */
}
.header-div-content {
  color: #fff;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 42.692px;
  text-transform: capitalize;
}
.header-div-content-mobile {
  color: #fff;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}
.rules-and-regulations {
  color: #e1e1e1;
  text-align: center;
  font-family: ' Lexend';
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}
.list-styles {
  color: #e1e1e1;
  text-align: justify;
  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 250% */
  text-transform: capitalize;
}
.list-styles-mobile {
  color: #e1e1e1;
  text-align: justify;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  padding: 15px;
}
// searching
/* Target the input element with the "search-input" class */
.search-input {
  // border: none; /* Remove the default border */
  outline: none; /* Remove the outline when focused */
}
.search-btn {
  border: none;
  background-color: transparent;
}
/* Optionally, you can add some additional styling to make it visually appealing */
.search-input:focus {
  // border: 1px solid #ccc; /* Add a border when the input is focused */
  border-radius: 4px; /* Add rounded corners */
}
//products page
.pductsstyleroot {
  background-size: cover;
  background-size: cover;
  background-position: center;
  height: 219px;
  // background-color: var(--primary-color);
  // opacity: .5,
}
// common
.zeal-btn-solid {
  background-color: rgba(236, 41, 39, 255);
  &:hover {
    background-color: #be302f;
  }
}
.btn404 {
  background-color: rgba(236, 41, 39, 255);
  &:hover {
    background-color: #be302f;
  }
}
.zeal-btn-outline {
  background-color: white;
  border: 1px solid var(--primary-color);
  color: rgba(236, 41, 39, 255);
  &:hover {
    background-color: #ffdad2;
    border: 1px solid rgba(236, 41, 39, 255);
    color: rgba(236, 41, 39, 255);
    .maingrid {
      position: relative;
      .childgrid {
        top: 7.5rem;
        position: absolute;
        left: 7rem;
      }
    }
  }
}
.popupbtn {
  background-color: rgba(236, 41, 39, 255);
  &:hover {
    background-color: #be302f;
  }
  width: 114px;

  letter-spacing: 1px;
}
.btndiv {
  display: flex;
  justify-content: center;
}
.home-page-mini-header {
  color: #e1e1e1;
  text-align: center;
  font-family: 'Lexend';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 43px;
}
.home-page-mini-header-mobile {
  color: #e1e1e1;
  text-align: center;
  font-family: 'Lexend';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

// section 2 style
.Transformmain-typo {
  text-align: center;
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 2rem;
  line-height: 28px;
}
.CardTransform {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
  box-shadow: unset;
  align-items: center;
  // width: 250px;

  .card-img {
    // height: 134px;
    // width: 114px;
    object-fit: contain;
  }

  .Card-content {
    // margin: 3px;
    padding: 8px;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .card-content {
    font-size: 24px;
    color: var(--grey-color);
    font-weight: 900 !important;
    text-align: center;
  }
}
.wrapper {
  word-wrap: break-word;
}
.custom-textfield input {
  font-size: 15px;
}
.custom-textfield .MuiInputBase-input {
  color: #d31412;
}

.custom-textfield .MuiOutlinedInput-root {
  border-radius: 0;
  // background-color: #c23b1c;
  // background-color: #d31412;
  border: 1px solid #d31412;
}

.custom-textfield .MuiOutlinedInput-root fieldset {
  border: none;
}

.custom-textfield ::placeholder {
  color: var(--grey-color);
}

.custom-textfield-booking input {
  font-size: 15px;
}
.custom-textfield-booking .MuiInputBase-input {
  color: #090909;
}

.custom-textfield-booking .MuiOutlinedInput-root {
  border-radius: 0;
  background-color: var(--secondary-color);
}

.custom-textfield-booking .MuiOutlinedInput-root fieldset {
  border: 2px solid var(--primary-color);
}

.custom-textfield-booking .MuiOutlinedInput-root :hover {
  border: var(--primary-color);
}

.custom-textfield-booking ::placeholder {
  color: var(--grey-color);
}

.custom-textfield-feedback input {
  font-size: 15px;
}
.custom-textfield-feedback .MuiInputBase-input {
  color: #090909;
}

.custom-textfield-feedback .MuiOutlinedInput-root {
  border-radius: 0;
  background-color: var(--secondary-color);
}

.custom-textfield-feedback .MuiOutlinedInput-root fieldset {
  border: 1px solid var(--primary-color);
}

.custom-textfield-feedback .MuiOutlinedInput-root :hover {
  border: var(--primary-color);
}

.custom-textfield-feedback ::placeholder {
  color: var(--grey-color);
}

.custom-inputlabel {
  color: #5f5c5c;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.lngpgicon {
  margin-right: 8px;
}
#sidebar-inActive {
  transition: all ease-in-out 0.3s;

  width: 65px;

  .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
    width: 65px;
  }
  .css-121b4uz-MuiListItemIcon-root {
    min-width: 48px;
  }
}

#sidebar-active {
  transition: all ease-in-out 0.3s;
  width: 280px;
  .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
    width: 280px;
  }
}
.logo {
  width: 300px;
  height: 40px;
  object-fit: contain;
}
.logo-sm {
  width: 300;
  height: 80;
  object-fit: contain;
}
.right-column {
  width: 50% !important;
}

.lngbtnmain {
  font-size: 16px;
}
.gridsun {
  margin-top: 5rem;
}
.boldfont {
  font-family: 'TomatoGroteskbold' !important;
}
.link {
  color: black;
}
.samesize {
  width: 261px;
  height: 261px;
}
.map-pointer {
  cursor: pointer !important;
}
.emojibtn {
  background: none !important;
  border-radius: 25px !important;
  padding: 0 !important;
}
.dnone {
  display: none;
}
.RighrArrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #fff;
  @media (max-width: 1024px) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: #fff;
    display: none;
  }
}
.search-bar {
  font-family: 'LexendRegular' !important;
  .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
    font-family: 'LexendRegular' !important;
    border: none !important;
    color: var(--primary-color);
  }
  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root:hover {
    border: none !important;
    padding: 12px 14px !important;
    border-radius: 0 !important;
  }
}
.LeftArrow {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: #fff;
  @media (max-width: 1024px) {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    color: #fff;
    display: none;
  }
}
.arrow {
  .slick-dots {
    display: none !important;
  }
}
.css-2ozy5a-MuiContainer-root {
  .slick-dots {
    padding-left: 1025px !important;
    @media (max-width: 1024px) {
      padding-left: 20px !important;
    }
    color: #65b492;
    & li button:before {
      color: #65b492 !important;
      & li button:after {
        color: #65b492 !important;
      }
    }
  }
}
.subtitle-1 {
  font-size: 14px;
  font-family: 'LexendLight' !important;
  color: #34708c !important;
  letter-spacing: 5px !important;
}
.subtitle-2 {
  font-size: 16px;
  font-family: 'LexendRegular' !important;
  color: #34708c;
  padding-top: 18px;
}

.drop-mini-text {
  font-family: 'LexendBold' !important;
  font-size: 16px;
  padding-left: 5px;
  @media (max-width: 1024px) {
    font-family: 'LexendBold' !important;
    font-size: 12px;
    padding-left: 5px;
  }
}
.drop-long-text {
  font-family: 'LexendRegular' !important;
  font-size: 16px;
  color: #34708c;
  font-weight: 500;
  background-color: #edf8f4 !important ;
  // padding: 15px;
  border-radius: 0px 0px 15px 15px;
  transition: opacity 0.5s ease-in-out;
  @media (max-width: 1024px) {
    font-family: 'LexendRegular' !important;
    font-size: 12px;
    color: #34708c;
    font-weight: 600;
    background-color: #edf8f4 !important ;
    // padding: 10px;
    border-radius: 0px 0px 15px 15px;
    transition: opacity 0.5s ease-in-out;
  }
}
// .accordion-shadow {
//   .MuiAccordion-region {
//     background: linear-gradient(90deg, rgba(200, 228, 214, 1) 0%, rgba(255, 255, 255, 1) 100%);
//   }
// }

.our-manifesto-text {
  font-family: 'LexendLight' !important;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  font-size: 16px;
  color: #34708c;
  padding-bottom: 25px;
  letter-spacing: 3px;
}
.sub-title-manifesto-regular {
  font-family: 'LexendRegular' !important;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  color: #34708c;
}
.sub-title-manifesto-bold {
  font-family: 'LexendBold' !important;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  color: #65b492;
  text-decoration: underline;
}
.image-box-home {
  background-color: #edf8f4;
  border-radius: 30px;
}
.image-box-project {
  background-color: #edf8f4;
  border-radius: 30px;
}
.mini-card-text {
  font-size: 15px;
  font-family: 'AnekLight' !important;
  color: #000000;
}
.footer-listes {
  font-family: 'LexendExtraLight' !important;
  font-size: 15px;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-family: 'LexendExtraLight' !important;
    font-size: 15px;
    font-weight: 400;
  }
}
.events-bg {
  // background-color: var(--secondary-color);
  // height: 400px;
  // width: 1000px;
  border-radius: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.all-event-bg {
  filter: grayscale(100%);
}
.main-banner {
  background-image: url('../../public/assets/images/Home/main-banner.jpg');
  background-position: center !important;
  background-repeat: no-repeat;
  // background-attachment: scroll;
  background-size: cover;
  height: 70vh;
}
.home-page-main-text1 {
  font-family: 'LexendRegular' !important;
  font-size: 35px !important;
  color: #34708c !important;
  padding-top: 0px;
}
.home-page-main-text2 {
  font-family: 'LexendBold' !important;
  font-size: 35px !important;
  color: #65b492 !important;
}
.mini-card-text-project-single {
  font-size: 16px;
  font-family: 'AnekLight' !important;
  color: #000000;
  padding-left: 15px;
  padding-right: 15px;
}
.view-project-home {
  color: #34708c;
  font-family: 'LexendBold' !important;
  font-size: 16px;
  text-transform: uppercase !important;
  border-bottom: 2px solid #9cd7bd !important;
  width: fit-content;
}
.view-projects-button {
  background-color: #65b492 !important;
  font-family: 'LexendBold' !important;
  color: #ffff;
  border-radius: 0px !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: none;
}
.testimonial {
  background-color: #edf8f4 !important;
  padding: 25px;
  border-radius: 10px;
  color: #34708c !important;
  text-align: center;
  height: 340px;
}
.poligon {
  clip-path: polygon(9% 0, 100% 0%, 100% 100%, 0% 100%);
}
.poligon-mob {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
}

.slick-dots {
  position: relative;
  left: 950px;
  width: 23%;
  @media (max-width: 1024px) {
    position: relative;
    left: 220px;
    width: 35%;
    padding-bottom: 0px;
  }
  @media (max-width: 768px) {
    position: relative;
    left: 0;
    width: 100%;
    padding-bottom: 0px;
    margin-bottom: 20px;
    top: 0;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  // height: 510px !important;
  @media (max-width: 1024px) {
    // height: 100% !important;
  }
}
.slick-dots li.slick-active button:before {
  font-size: 16px !important;
}
.slick-dots li button:before {
  color: #65b492 !important;
  font-size: 12px !important;
}
.slick-dots li button:after {
  color: #65b492 !important;
  font-size: 14px !important;
}

.testimonial-text {
  font-family: 'LexendExtraLight' !important;
  font-size: 16.48px;
  text-align: center;
}
.testimonial-username {
  font-family: 'LexendBold' !important;
  font-size: 14px;
  color: var(--secondary-color) !important;
}
.testimonial-designation {
  color: var(--primary-color) !important;
  font-size: 11px;
  font-family: 'LexendBold' !important;
}
.footer-text {
  color: #ffffff;
  font-family: 'LexendExtraLight' !important;
  font-size: 15px;
}
.footer-follow {
  font-family: 'LexendLight' !important;
  font-size: 16px;
}
.osperb-font {
  font-family: 'CeraProLight' !important;
  font-size: 13px;
}
.project-single-name {
  font-family: 'LexendSemiBold' !important;
  color: var(--secondary-color);
  font-size: 22px;
  @media (max-width: 1024px) {
    font-family: 'LexendSemiBold' !important;
    color: var(--secondary-color);
    font-size: 20px;
  }
}
.project-single-designation {
  font-family: 'AnekLight' !important;
  font-size: 16px;
  color: var(--primary-color);
  text-transform: uppercase;
}
.project-button1 {
  background-color: var(--primary-color);
  color: #ffffff;
  box-shadow: none;
  font-size: 20px;
  font-family: 'LexendBold' !important;
  border-radius: 0px;

  &:hover {
    background-color: #ffffff;
    color: var(--primary-color);
    border: 1px solid;
  }
}

.project-button2 {
  color: var(--primary-color);
  box-shadow: none;
  font-size: 16px;
  font-family: 'LexendRegular' !important;
  text-transform: uppercase;
  margin-top: 5px;
  font-weight: 400;
  border-radius: 0px;
  border: 1px solid #65b492 !important;
}

.project-button2:hover {
  color: var(--primary-color) !important;
  background-color: red($color: #000000);
}
.specification-list-heading {
  font-family: 'LexendSemiBold' !important;
  font-size: 16px;
  color: var(--secondary-color);
  text-transform: uppercase;
  border-bottom: 1px solid #65b492;
}
.project-single-page-points {
  font-size: 16px;
  font-family: 'AnekLight' !important;
  color: #000000;
}
.css-7jhhar-MuiTypography-root-MuiLink-root {
  outline: none !important;
}
.tab-box {
  border-bottom: 1px !important;
  border-color: #65b492 !important;
  .css-wdsdfe-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #65b492 !important;
    font-family: 'LexendLight' !important;
    font-size: 14px;
    text-transform: uppercase;
    .css-wdsdfe-MuiButtonBase-root-MuiTab-root {
      font-family: 'LexendLight' !important;
      font-size: 16px !important;
      text-transform: uppercase !important;
    }
    // background-color: #65b492 !important;
    .css-6d3pg0-MuiTabs-indicator {
    }
  }
}
.css-1p30j7e,
.css-6d3pg0-MuiTabs-indicator {
  background-color: #65b492 !important;
}
.tab-text {
  font-family: 'LexendLight' !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  color: #34708c !important;
}
.tab-parent {
  padding: 10px;
}
.event-calendar-dates {
  color: #34708c !important;
  font-size: 12px;
  font-family: 'LexendRegular' !important;
  @media (max-width: 1024px) {
    color: #34708c !important;
    // font-size: 9px;
    font-family: 'LexendRegular' !important;
  }
}
.event-time {
  color: #65b492 !important;
  font-size: 12px;
  font-family: 'LexendBold' !important;
  @media (max-width: 1024px) {
    color: #65b492 !important;
    font-size: 10px;
    font-family: 'LexendBold' !important;
  }
}
.event-location {
  color: #34708c !important;
  font-size: 12px;
  font-family: 'LexendRegular' !important;
  @media (max-width: 1024px) {
    color: #34708c !important;
    // font-size: 8px;
    font-family: 'LexendRegular' !important;
  }
}

.font-drawer-link {
  font-family: 'LexendRegular' !important;
}
.css-13xfq8m-MuiTabPanel-root {
  padding: 0px !important;
}
.event-title {
  font-family: 'LexendSemiBold' !important;
  font-size: 22px;
  color: var(--secondary-color);
}
.event-description {
  font-family: 'LexendLight' !important;
  font-size: 14px;
  color: var(--primary-color);
}
.letter-events {
  font-family: 'LexendRegular' !important;
  font-size: 26px;
  color: var(--primary-color);
}
.time-string {
  color: var(--secondary-color);
  font-family: 'LexendBold' !important;
  font-size: 10px;
  margin-top: -7px;
}
.event-time-single {
  color: var(--primary-color);
  font-size: 12px;
  font-family: 'LexendRegular' !important;
}
.footer-address {
  font-family: 'LexendExtraLight' !important;
  font-size: 16px;
}
.footer-copyRight {
  font-family: 'CeraProLight' !important ;
  font-size: 13px !important;
}
.follow-us {
  font-family: 'OxaniumLight' !important ;
}
.join-ib-button {
  font-family: 'LexendBold' !important;
}
.css-4s6chl-MuiStack-root {
  display: flex !important;
  align-items: center !important;
}
.direct-board {
  font-family: 'LexendSemiBold' !important;
  font-size: 20px;
  color: var(--secondary-color) !important;
}
.member-title {
  font-family: 'LexendBold' !important;
  font-size: 17px;
  color: var(--secondary-color) !important;
  max-width: 220px;
  @media (max-width: 1024px) {
    font-family: 'LexendBold' !important;
    font-size: 13px;
    color: var(--secondary-color) !important;
  }
}
.member-title-2 {
  font-family: 'LexendBold' !important;
  font-size: 17px;
  color: var(--secondary-color) !important;
  max-width: 240px;
  @media (max-width: 1024px) {
    font-family: 'LexendBold' !important;
    font-size: 13px;
    color: var(--secondary-color) !important;
  }
}
.member-designation {
  font-family: 'LexendLight' !important;
  font-size: 12px;
  color: var(--primary-color) !important;
  @media (max-width: 1024px) {
    font-family: 'LexendLight' !important;
    font-size: 9px;
    color: var(--primary-color) !important;
  }
}
.policy-title {
  font-family: 'LexendSemiBold' !important;
  font-size: 22px;
  color: var(--secondary-color);
}
.policy-sub-heading {
  font-family: 'LexendSemiBold' !important;
  font-size: 16px;
  color: var(--secondary-color);
}
.description-text-normal {
  font-family: 'AnekLight' !important;
  font-size: 16px;
  color: #000000;
}
.description-text-bold {
  font-family: 'AnekSemiBold' !important;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
}
.home-page-mobile-banner-text1 {
  font-size: 32px;
  font-family: 'LexendRegular' !important;
  color: var(--primary-color);
}
.home-page-mobile-banner-text2 {
  font-size: 32px;
  font-family: 'LexendBold' !important;
  color: var(--secondary-color);
}
.mobile-link-text {
  font-family: 'LexendRegular' !important;
  font-size: 11px;
}
.modal-header {
  font-family: 'LexendSemiBold' !important;
  font-size: 16px;
  color: var(--secondary-color);
}
.submit-button {
  background-color: var(--secondary-color);
  font-family: 'LexendBold' !important;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 8px;
  width: 100%;
  height: 50px;
  .css-p8fv86-MuiButtonBase-root-MuiButton-root:hover {
    background-color: var(--secondary-color);
    color: #ffffff;
  }
}

.field-custom {
  height: 50px;
  width: 100%;
  border: 1px solid #65b492;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 0px;
  color: #65b492 !important ;
  .css-91318-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused {
    border: 1px solid #65b492 !important;
  }
  .css-913t18-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #65b492 !important;
  }
  .css-13isf56-MuiInputBase-input-MuiOutlinedInput-input {
    color: var(--secondary-color) !important;
  }
  .css-913t18-MuiInputBase-root-MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: var(--secondary-color);
    outline: none;
  }
}

.field-custom:focus-visible {
  height: 50px;
  width: 100%;
  border: 1px solid #65b492 !important;
  outline: none !important;
  padding: 10px;
}

// .app-bar.css-1v1dkay-MuiGrid-root {
//   width: unset;
//   margin-left: 0px;
// }
// mediaquery
@media (max-width: 1024px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 10px !important;
  }
  .lngbtnmain {
    font-size: 13px;
  }
  .left_content_container {
    width: 500px !important;
    top: 30px !important;
    left: 60px;
    padding: 5px 45px;
  }
  .logo {
    width: 149px;
    height: 47px;
  }
}
@media (max-width: 769px) {
  .dnone {
    display: flex;
  }
  .headingh1 {
    margin-top: 1rem;
  }
  .right-column {
    width: 75% !important;
  }
  .contntbox {
    width: 100% !important;
  }
  .imagebox {
    width: 100% !important;
    // padding-top: 12px;
  }
  .left_content_container {
    position: unset !important;
    width: 100% !important;
    padding-left: 39px !important;
  }
  .imagecontainer {
    position: unset !important;
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .formsection {
    width: 337px !important;
  }
  .left-column {
    max-width: unset !important;
  }
  .left-column {
    margin-left: 0rem !important;
  }
  .quickimg {
    max-width: 722px !important;
  }
  .bookbutton {
    margin-bottom: 2rem !important;
  }
  .spleft {
    margin-top: 9rem !important;
  }
}
@media (max-width: 414px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 9px !important;
  }
}
@media (max-width: 426px) {
  .hidebtn1 {
    display: none;
  }

  .marBotm {
    margin-bottom: 35px;
  }
  .CardTransform {
    .Card-content {
      padding: 0px !important;
    }
  }

  .jobdetails {
    width: 60%;
  }
  .maindivrow {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  .gridsun {
    margin-top: 0px;
  }
  .btnsize {
    font-size: 13px;
  }
  .abtpadding {
    padding: 1rem;
  }
  .EstimateContainer {
    .css-1yxm0zd-MuiPaper-root-MuiCard-root {
      margin: 10px;
      box-shadow: none;
    }
  }
  .headingh1 {
    margin-top: 0rem;
  }
  .css-v5sfno-MuiPaper-root-MuiCard-root {
    margin-left: 18px;
    margin-right: 18px;
  }
  .bookbutton {
    font-size: 11px;
    width: 176px;
  }
  .lngbtn {
    font-size: 12px;
    // width: 226px !important;
    width: auto;
  }
  .lngbtnmain {
    // font-size: 11px;
    width: auto;
  }
  .left_content_container {
    padding: 23px 18px;
  }
  .lngpgicon {
    // margin-right: 3px;
  }
  .right-column {
    width: 100% !important;
  }
  .left-column {
    width: 100% !important;
  }
  .left-column {
    width: 100% !important;
  }
  .rptop {
    margin-top: 2rem;
  }
  .mbbtn {
    width: 153px !important;
  }
  .custom-inputlabel {
    font-size: 12px;
  }
  .custom-textfield input {
    font-size: 12px;
  }
  .css-13isf56-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 10px;
  }
  .request-btn {
    font-size: 16px;
    font-family: 'LexendBold' !important;
    text-transform: uppercase;
    align-items: center;
    display: flex !important;
  }

  .footerlogo {
    // opacity: 0.3;
  }
  .spleft {
    margin-top: 0rem !important;
  }
  .left-column {
    padding-left: 18px !important;
    padding-right: 15px !important;
  }
  .gridsun {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .bookbutton {
    margin-bottom: unset !important;
  }
  .quickimg {
    max-width: 580px !important;
  }
  .landgmainsection {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px;
  }
  .image-container-mob {
    @media (max-width: 480px) {
      position: relative;
      min-height: 100px;
      display: flex;
    }
    .css-mhc70k-MuiGrid-root {
      height: 110px;
    }
  }
  .Card-content {
    margin: 0px;
    padding: 0px;
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 10px !important;
  }
  .logo {
    width: 164px;
    height: 37px;
  }
  .Transformmain-typo {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .card-content {
    font-size: 18px !important;
  }

  .zcareimg {
    width: 52px !important;
    height: 60px !important;
  }
  .transparentdiv {
    width: 113px !important;
    height: 113px !important;
    // top:28% !important;
  }
  .contentsection {
    padding: 2rem 1.5rem !important;
  }
  .formsection {
    width: 394px !important;
    padding-top: 0rem !important;
  }
}

@media (max-width: 384px) {
  .lngbtn {
    font-size: 9px !important;
  }
}
@media (max-width: 358px) {
  .hidebtn {
    display: none;
  }
}
@media (max-width: 400px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 9px !important;
  }
}
@media (max-width: 345px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 7px !important;
  }
}
@media (max-width: 390px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 8px !important;
  }

  .logo {
    width: 122px;
    height: 37px;
  }
}
@media (max-width: 321px) {
  .jobdetails {
    width: 55%;
    margin-right: 1rem;
  }
  .zeal-btn-solid {
    font-size: 10px;
  }
  .mbbtn {
    font-size: 13px;
  }
  .lngbtn {
    font-size: 10px;
  }
  .select {
    font-size: 10px;
  }
  .click,
  .book {
    font-size: 14px;
  }
  .footerlogo {
    height: 80px !important;
    width: 300px;
  }
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 6px !important;
  }
  .lngbtnmain {
    // font-size: 6px;
  }
  .formsection {
    width: 287px !important;
  }
  .lngpgicon {
    margin-right: 11px;
  }
  .lngbtn {
    text-align: center;
  }
}
// #main-body-sidebar-active {
// }
#main-body-sidebar-inActive {
  .css-1oqqzyl-MuiContainer-root {
    @media (min-width: 1200px) {
      max-width: none;
    }
  }
}

.session-table {
  td {
    vertical-align: top;
  }
}

.product-measurement-input {
  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 0;
  }
  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input {
    color: var(--grey-color);
  }
}

.my-DateCalendar {
  // live
  .MuiPickersDay-dayWithMargin, // other dates
  .MuiPickersDay-dayWithMargin.Mui-selected, // selected date
  .MuiPickersDay-dayWithMargin.Mui-disabled,//  disabled dates
  .MuiPickersDay-hiddenDaySpacingFiller,// empty date
  .MuiDayCalendar-weekDayLabel, // week label
  // dev
  .css-12l9xv4-MuiButtonBase-root-MuiPickersDay-root, // selected date
  .css-ka6agc-MuiButtonBase-root-MuiPickersDay-root, // other dates and disabled
  .css-1602a6u-MuiPickersDay-root, // empty date
  .css-1n0erti-MuiTypography-root-MuiDayCalendar-weekDayLabel // week label
  {
    font-size: 15px !important;
    width: 60px !important;
    height: 60px !important;
    @media (max-width: 500px) {
      font-size: 13px !important;
      width: 35px !important;
      height: 35px !important;
    }
  }

  .css-12l9xv4-MuiButtonBase-root-MuiPickersDay-root:hover,
  .MuiPickersDay-dayWithMargin:hover,
  .css-ka6agc-MuiButtonBase-root-MuiPickersDay-root:hover {
    background-color: #ffd4cc !important;
  }
  //
  .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition,
  .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
    // date container
    min-height: 400px !important;
  }
  .MuiDateCalendar-root,
  .css-1r8uv9g-MuiDateCalendar-root {
    max-height: 520px !important;
  }
  .MuiPickersDay-dayWithMargin.Mui-selected,
  .css-12l9xv4-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: var(--primary-color) !important;
  }
  .MuiPickersCalendarHeader-root,
  .css-nk89i7-MuiPickersCalendarHeader-root {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 65px;
    padding-right: 40px;
    @media (max-width: 500px) {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 70px;
      padding-right: 45px;
    }
  }
}

.floating-card {
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.5s;
  bottom: 0px;
  &.active {
    opacity: 1;
    visibility: visible;
    bottom: 80px;
    z-index: 99;
  }
}

.custom-textfield-booking {
  &.location {
    .MuiInputBase-root {
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      padding-left: 6px;
    }
    .css-12vka4q-MuiFormLabel-root-MuiInputLabel-root {
      top: 7px !important;
    }
  }
}

.search_map {
  position: absolute;
  top: 10px;
  left: 220px;
  width: 100%;
  max-width: 400px;
  z-index: 10;
  @media (max-width: 500px) {
    position: absolute;
    top: 60px;
    left: 5px;
    width: 100%;
    max-width: 400px;
    z-index: 10;
  }
}
.search_map input {
  padding: 0 15px;
  font-size: 1rem;
  width: 80%;
  background: #fff;
  height: 40px;
  border: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  // border-radius: 5px;
}
.locate_map {
  position: absolute;
  top: 4rem;
  right: 9px;
  // z-index: 10;
  border: none;
  height: 35px;
  width: 40px;
  border-radius: 5px;

  span {
    height: 20px !important;
    width: 20px !important;
  }
}
.searched_list {
  padding: 1rem;
  padding-top: 5px;
  background: white;
  width: 80%;
  border-radius: 0 0 10px 10px;
  margin-top: 1px;
  z-index: 999;
}
.product-title {
  color: #fff;
  font-family: 'Lexend';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 2px;
}
.product-price {
  color: #fff;
  font-family: 'DM Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 8px;
}
.old-price {
  color: #8d8d8d;
  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: line-through;
}
.product-offer-percentage {
  color: #f49935;
  font-family: 'DM Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.category-input {
  margin-bottom: 53px !important;
}
.css-13vkswh-MuiInputBase-root-MuiInput-root-MuiSelect-root::after {
  border: none;
}
.css-13vkswh-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled, .Mui-error):before {
  border: transparent;
}

.content-box-main {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1024px) {
    // width: 100%;
    height: 200px;
  }
  height: 360px;
}

.slider {
  height: 600px;
  // background-color: #293132;
  text-align: center;
  position: relative;
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px;
  // animation: slideColor 10s forwards infinite;
}

.caption {
  // line-height: 100px;
  font-size: 50px;
  color: #fff;
  position: relative;
  top: 50% !important;
  transform: translateY(-50%);
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  margin-left: -300px;
}
.caption-mobile {
  font-size: 50px;
  color: #fff;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}

.text-box {
  display: inline-block;
  position: relative;
}

.text-box div {
  display: inline-block;
  position: absolute;
  top: -200px;
  transform: rotateX(-90deg);
  opacity: 0;
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
  animation-timing-function: ease;
  // color: #d24a28, #fea23d;
  background: linear-gradient(101deg, #d24a28 52.83%, #fea23d 112.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Lexend';
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
}
.slider-mobile {
  height: 600px;
  text-align: center;
  position: relative;
}

.caption-mobile {
  font-size: 50px;
  color: #fff;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
}

.text-box-mobile {
  background: linear-gradient(103deg, #d24a28 33.09%, #fea23d 94.28%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: ' Lexend';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
}
.say-hello {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  // line-height: 70px; /* 145.833% */
}

.text-box div:nth-child(1) {
  animation: rollDown 10s forwards infinite;
  margin-top: 20px;
}

.text-box div:nth-child(2) {
  animation: rollDown2 10s forwards infinite;
  margin-top: 20px;
}

.text-box div:nth-child(3) {
  animation: rollDown3 10s forwards infinite;
  margin-top: 20px;
}

@keyframes rollDown {
  0% {
    top: -200px;
    transform: rotateX(-90deg);
  }
  11% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }
  22% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }
  33% {
    top: 50px;
    transform: rotateX(30deg);
    opacity: 0;
  }
}

@keyframes rollDown2 {
  33% {
    top: -200px;
    transform: rotateX(-90deg);
  }
  44% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }
  55% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }
  66% {
    top: 50px;
    transform: rotateX(30deg);
    opacity: 0;
  }
}

@keyframes rollDown3 {
  66% {
    top: -200px;
    transform: rotateX(-90deg);
  }
  77% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }
  88% {
    top: -74px;
    transform: rotateX(0deg);
    opacity: 1;
  }
  99% {
    top: 50px;
    transform: rotateX(30deg);
    opacity: 0;
  }
}

.string {
  display: flex;
  flex-direction: column;
  text-align: center;
  animation: move 4s infinite;
}

.greeting {
  position: relative;
  top: 8.6vmin;
  animation: white-out 5s infinite;
}
.string-mobile {
  display: flex;
  flex-direction: column;
  text-align: center;
  animation: move 4s infinite;
}

.greeting {
  position: relative;
  top: 3.6vmin;
  animation: white-out 5s infinite;
}

.closure::after {
  content: '';
  position: absolute;
  height: 25vmin;
  width: 40vmin;
  background: #282c34;
  transform: translate(-45vmin, -24.5vmin);
  display: none;
}

.closure::before {
  content: '';
  position: absolute;
  height: 25vmin;
  width: 40vmin;
  background: #282c34;
  transform: translate(-40vmin, 5vmin);
  display: none;
}

.en {
  opacity: var(--textfield-standard-input-small-pt, 1);
  background: linear-gradient(101deg, #d24a28 52.83%, #fea23d 112.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Lexend';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.es {
  opacity: var(--textfield-standard-input-small-pt, 1);
  background: linear-gradient(101deg, #d24a28 52.83%, #fea23d 112.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Lexend';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}

.de {
  opacity: var(--textfield-standard-input-small-pt, 1);
  background: linear-gradient(101deg, #d24a28 52.83%, #fea23d 112.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Lexend';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
}
.en-mobile {
  opacity: var(--textfield-standard-input-small-pt, 1);
  background: linear-gradient(101deg, #d24a28 52.83%, #fea23d 112.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Lexend';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}

.es-mobile {
  opacity: var(--textfield-standard-input-small-pt, 1);
  background: linear-gradient(101deg, #d24a28 52.83%, #fea23d 112.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Mail Sans  Roman';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}

.de-mobile {
  opacity: var(--textfield-standard-input-small-pt, 1);
  background: linear-gradient(101deg, #d24a28 52.83%, #fea23d 112.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Lexend';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}

@keyframes move {
  25% {
    transform: translatey(6.8vmin);
  }
  50% {
    transform: translatey(-8vmin);
  }
  75% {
    transform: translatey(-23.8vmin);
  }
}
.submit-button-home {
  .css-1tc7jod-MuiButtonBase-root-MuiButton-root {
    border-radius: 8px;
  }
}
.submit-button-home {
  .css-jj2ztu > :not(style):not(style) {
    border-radius: 8px;
  }
}
.course-edited-main-head {
  opacity: var(--textfield-standard-input-small-pt, 1);
  background: linear-gradient(92deg, #d24b28 5.64%, #ee8236 100.53%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Lexend';
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
.course-main-button1-mobile {
  border-radius: 8px;
  background: #072256;
  color: white;
  height: 45px;
  width: auto;
}
.course-main-button2-mobile {
  border-radius: 8px;
  border: 2px solid #393939;
  margin-left: 25px;
  height: 45px;
  width: auto;
  color: white;
}
.stem-exp-course-head {
  color: #e1e1e1;
  font-family: 'Lexend';
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px; /* 130.435% */
}
.course-main-button1 {
  border-radius: 8px;
  background: #072256;
  color: white;
  height: 45px;
  width: 100%;
}
.course-main-button2 {
  border-radius: 8px;
  border: 2px solid #393939;
  margin-left: 25px;
  height: 45px;
  width: 80%;
  color: white;
}
.course-boxes {
  border-radius: 30px;
  border: 1px solid #282828;
  width: 280px;
  height: auto;
  min-height: 180px;
}
.course-boxes-mobile {
  border-radius: 30px;
  border: 1px solid #282828;
  width: auto;
  height: auto;
  min-height: 180px;
}
.course-box-title {
  font-family: 'Lexend';

  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.course-main-image-mobile {
  .css-nen11g-MuiStack-root {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.img-container-course {
  min-height: 70px;
  max-height: 80px;
  margin-bottom: 20px;
}

.img-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lab-Curriculum-boxes {
  border-radius: 30px;
  background: #212121;
  width: 280px;
  height: auto;
  min-height: 180px;
}
.lab-curriculum-box-title {
  color: #fff;
  text-align: center;
  font-family: 'Lexend';
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.img-container-lab-curriculum {
  min-height: 70px;
  max-height: 80px;
  margin-bottom: 20px;
}

.lab-curriculum-img-parent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-lab-boxes {
  border-radius: 28px;
  background: #212121;
  width: 100%;
  height: 120px;
  min-height: 140px;
  display: flex;
  align-items: center;

  justify-content: center;
}
.feature-lab-boxes-mobile {
  border-radius: 18px;
  background: #212121;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;

  justify-content: center;
}
.feature-lab-box-title {
  color: #fff;
  // text-align: center;
  font-family: 'Lexend';
  padding: 15px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 35.455px; /* 147.727% */
  padding-right: 50px;
  padding-left: 50px;
}
.feature-lab-box-title-mobile {
  color: #fff;
  // text-align: center;
  font-family: 'Lexend';
  font-size: 16px;
  font-style: normal;
}

.img-parent-feature {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px;
}
.img-parent-feature-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35px;
}

.lab-Curriculum-boxes {
  border-radius: 30px;
  border: 1px solid #282828;
  width: 250px;
  height: auto;
  min-height: 180px;
  max-height: 150px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
}
.lab-curriculum-box-title {
  color: #fff;
  text-align: center;
  font-family: 'Lexend';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.lab-curriculum-img-parent {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-collage-boxes {
  border-radius: 30px;
  background: #212121;
  width: 475px;
  height: 120px;
  min-height: 130px;
  display: flex;
  align-items: center;
  padding: 25px;
  justify-content: center;
}
.feature-collage-boxes-mobile {
  border-radius: 20px;
  background: #212121;
  width: 100%;
  height: auto;
  min-height: 130px;
  display: flex;
  align-items: center;
  padding: 25px;
  justify-content: center;
}

.img-parent-collage {
  padding: 5px;
}
.img-container-collage-feature {
  height: 90%;
  object-fit: contain;
  max-width: unset !important;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-container-collage-feature-mobile {
  height: 70%;
  object-fit: contain;
  max-width: unset !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature-collage-box-title {
  color: #fff;
  // text-align: center;
  font-family: 'Lexend';
  padding: 0px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
}
.feature-collage-box-title-mobile {
  color: #fff;
  // text-align: center;
  font-family: 'Lexend';
  padding: 5px;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}
.image-mini-carousal:focus-visible {
  outline: none !important;
}
.image-big-carousal:focus-visible {
  outline: none !important;
}
.text-flip-card-title {
  text-align: start !important;
}
.online-offline-course-banner-mobile {
  .online-offline-course-banner-title-mobile {
    color: #fff;
    font-family: 'Lexend';
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 107.523%; /* 43.009px */
  }
}
.online-offline-course-banner-title {
  color: #fff;
  font-family: 'Lexend';
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 107.523%; /* 43.009px */
}
.online-offline-course-banner-description {
  color: #949494;
  font-family: 'DM Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}
.online-offline-course-banner-description-mobile {
  color: #949494;
  font-family: 'DM Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.image-box-lab-mobile {
  .css-uwwqev {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.lab-experience-box-title {
  color: #f49935;
  font-family: 'Lexend';
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 107.523%; /* 43.009px */
}
.lab-experience-box-title-description {
  color: #949494;
  font-family: 'DM Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 115% */
}
.collage-internship-title {
  color: #fff;
  text-align: center;
  font-family: ' Lexend';
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.collage-internship-experience {
  color: #f49935;
  text-align: center;
  font-family: 'Lexend';
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.collage-internship-title-mobile {
  color: #fff;
  text-align: center;
  font-family: ' Lexend';
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.collage-internship-experience-mobile {
  color: #f49935;
  text-align: center;
  font-family: 'Lexend';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.test-boxes {
  border-radius: 30px;
  background: #212121;
  width: 240px;
  height: auto;
  min-height: 130px;
}

.test-img-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  .css-eeuz9e-MuiStack-root {
    padding-top: 0px;
  }
}
.img-container-test {
  min-height: 70px;
  max-height: 80px;
  margin-bottom: 10px;
}
.test-box-title {
  color: #fff;
  text-align: center;
  font-family: 'DM Sans';
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.star-img-parent {
  display: flex;
  // align-items: center;
  // justify-content: center;
  flex-direction: row;
  height: auto;
}
.star-img {
  width: 35px;
  padding-bottom: 35px;
  object-fit: contain;
}
.star-boxes {
  border-radius: 30px;
  background: #212121;
  width: 240px;
  height: auto;
  min-height: 160px;
}
.star-box-score {
  color: #e1e1e1;
  text-align: center;
  font-family: 'DM Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 83.333% */
  text-transform: uppercase;

  padding-bottom: 15px;
}
.star-box-scholarship {
  opacity: var(--textfield-standard-input-small-pt, 1);
  color: #e1e1e1;
  font-family: 'DM Sans';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
}
.enroll-card {
  width: 100%;
  height: 200px;
  flex-shrink: 0;
  border-radius: 30px;
  opacity: 1;
  background: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
}
.enroll-card-mobile {
  width: auto;
  height: auto;
  flex-shrink: 0;
  border-radius: 42px;
  opacity: 1;
  background: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.enroll-card-description {
  opacity: 1;
  color: #949494;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.enroll-card-title {
  color: #e1e1e1;
  text-align: center;
  font-family: 'Lexend';
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
}
.enroll-card-description-parent {
  text-align: center;
}
.video-carousal {
  iframe {
    border-radius: 20px !important;
  }
}
.html5-video-player:not(.ytp-transparent),
.html5-video-player.unstarted-mode,
.html5-video-player.ad-showing,
.html5-video-player.ended-mode {
  .ytp-chrome-top,
  .ytp-chrome-bottom {
    display: none !important;
  }
}
.header-login-button {
  border-radius: 23.014px;
  opacity: var(--textfield-standard-input-small-pt, 1);
  background: #05286c;
  box-shadow: none;
}
.enquiry-form-input-border:focus-visible {
  outline: none;
}
.enquiry-form-input {
  height: 54px;
  border-radius: 10px;
  background-color: #323232;
  border: none;
  width: 100%;
  color: rgb(233, 229, 229);
  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root {
    color: white;
  }
}
.enquiry-form-input-comment {
  // height: 80px !important;
  border-radius: 10px;
  background-color: #323232;
  border: none;
  width: 100%;
  // color: rgb(233, 229, 229) !important;
  .css-913t18-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline:focus-visible {
    border: none !important;
    outline: none;
  }
  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root {
    color: white !important;
  }
}

.enquiry-form-input-comment-2 {
  border-radius: 10px;
  background-color: #323232;
  border: none !important;
  width: 100%;
}

.enquiry-form-input-comment-2:active {
  border: none !important;
}
